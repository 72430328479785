import axios, { formToJSON } from "axios";
import { localStorageGet, currencyCode } from "./common";
import config from '../appConfig';

export const baseURL = config.API_DOMAIN.trim();

//API Call to tokenize card
export const tokenRequest = async (sessionHref, cardHolderName, saveCard) => {


    const url = baseURL + "/WPGenerateToken"

    const requestBody =
    {
        cardHolderName: cardHolderName,
        sessionHref: sessionHref,
        saveCard: saveCard,
    };


    console.log(localStorageGet('token'));

    try {
        const response = await makePostRequest(url, requestBody);

        //Check response status
        if (response.status !== 200) {
            throw new Error('Failed to generate token')
        }

        //Extract Parameter from response
        const tokenHref = response.data.message._embedded.token.tokenPaymentInstrument.href;
        const paymentAuthLink = response.data.message._embedded.verification._links['payments:cardOnFileAuthorize'].href;
        const cardNumberMasked = response.data.message._embedded.token.paymentInstrument.cardNumber;
        const cardHolderName = response.data.message._embedded.token.paymentInstrument.cardHolderName;

        return [tokenHref, paymentAuthLink,cardNumberMasked,cardHolderName];

    } catch (error) {
        return [null, null];
    }
}

//API Call to Initialize 3D Secure
export const initialize3ds = async (tokenHref) => {
    const url = baseURL + "/WP3DSDeviceInitialization"

    const requestBody =
    {
        "paymentInstrumenthref": tokenHref
    }

    try {
        const response = await makePostRequest(url, requestBody);

        //Check Response Status
        if (response.status !== 200) {
            console.error('Failed to generate token')
            return [null, null, null]
        }

        //Extract Parameter from Response
        const transactionReference = response.data.message.transactionReference;
        const ddc_jwt = response.data.message.deviceDataCollection.jwt;
        const ddc_url = response.data.message.deviceDataCollection.url;
        const ddc_bin = response.data.message.deviceDataCollection.bin;

        return [ddc_jwt, ddc_url, ddc_bin, transactionReference];


    } catch (error) {
        return [null, null, null, null]

    }

}
//API call for Authenticating 3d security
export const authenticate3ds = async (amount, tokenHref, collectionRef, transactionRef) => {
    console.log("AUTHENTICATING");
    const url = baseURL + "/WP3DSDeviceAuthendication"

    const requestBody = {
        "transactionReference": transactionRef,
        "paymentInstrumenthref": tokenHref,
        "valueAmount": amount * 100,
        "collectionReference": collectionRef,
        "acceptHeader": "text/html", //Get From User's device
        "userAgentHeader": "Mozilla/5.0 (Windows NT 6.1; Win64; x64; rv:47.0)", //Get from user device
        "windowSize": "600x400",
    }

    try {
        const response = await makePostRequest(url, requestBody);

        //Check Response Status
        if (response.status !== 200) {
            console.log('Failed to generate token')
            return [null, null, null, null, null]
        }

        //Extract Parameter from Response
        const outcome = response.data.message.outcome;
        if (outcome === "challenged") {
            console.log("challenge")
            const jwt = response.data.message.challenge.jwt;
            const authorize_url = response.data.message.challenge.url;
            const challengeRef = response.data.message.challenge.reference;
            return [outcome, jwt, authorize_url, challengeRef, null];

        } else if (outcome === "authenticated") {
            console.log("AUTHENTICATED");
            const authVer = response.data.message.authentication.version;
            const authVal = response.data.message.authentication.authenticationValue;
            const authEci = response.data.message.authentication.eci;
            const authTransactionId = response.data.message.authentication.transactionId;
            return [outcome, authVer, authVal, authEci, authTransactionId];
        } else {
            return null //If outcome is not challenged or authenticated => Error
        }

    } catch (error) {
        console.log("Check: " + error);
        return [null, null, null, null, null]

    }


}

//API call for authorizing paymnet
export const authorizePayment = async (amount, tokenHref, authVer, authEci, authVal, authTransactionId, resourceUrl, transactionRef) => {
    console.log("AUTHORIZE PAYMENT");
    const url = baseURL + "/WP3DSDeviceAuthorization"

    const requestBody =
    {
        "transactionReference": transactionRef,
        "paymentInstrumenthref": tokenHref,
        "valueAmount": amount * 100,
        "authenticationVersion": authVer,
        "authenticationEci": authEci,
        "authenticationValue": authVal,
        "authenticationTransactionId": authTransactionId,
        "resourceUrl": resourceUrl
    }

    try {
        const response = await makePostRequest(url, requestBody)

        console.log(response.data);
        if (response.data.message.outcome != 'authorized') {
            return null;
        } else {
            return [response.data.message.outcome, response.data.message._links["payments:settle"].href];
        }
    } catch (error) {
        return null;

    }

}


//API Call for settling authorized payment
export const settlePayment = async (transactionReference, settleUrl, depositAmount, description, cardNumber = null, cardHolderName = null, isSuccessfulTransaction) => {
    const url = baseURL + "/WP3DSPaymentSettlement"

    const requestBody =
    {
        "transactionReference": transactionReference,
        "paymentStatus": isSuccessfulTransaction,
        "resourceUrl": settleUrl,
        "depositAmount": depositAmount,
        "description": description,
        "response":{
            "decimal_amount":depositAmount,
            "card_holder":cardHolderName,
            "card_number":cardNumber
        }
    }

    try {
        const response = await makePostRequest(url, requestBody);
        return response.data;
    } catch (error) {
        return null;
    }
}

//API Call for deleting saved card
export const deleteCard = async (cardNumber) => {
    console.log("DELETE SAVED CARD");
    const url = baseURL + "/WPDeleteSavedCard"

    const requestBody =
    {
        "cardNumber": cardNumber
    }

    try {
        const response = await makePostRequest(url, requestBody);
        console.log(response);
        return response.data;
    } catch (error) {
        return null;
    }
}

//API for polling method in case of challenge
//If 3DS is challenged, this method returns when the user completed the challenge
export const challengePolling = (tref) => {
    console.log("POLLING CALL");
    const timeout = 60000; // 60 seconds
    const pollInterval = 1000; // 1 second
    const startTime = Date.now();

    return new Promise((resolve, reject) => {
        const poll = setInterval(async () => {
            try {
                const response = await axios.post(baseURL + '/WPChallengePolling', {
                    "transactionReference": tref
                }, {
                    headers: {
                        "Authorization": localStorageGet('token')
                    }
                });
                const error = response.data.errorNo;

                console.log("POLLING", error);

                if (error === 0) {
                    clearInterval(poll); // Stop polling
                    resolve(true); // User submitted OTP form => Continue with authorization
                }

                if (Date.now() - startTime > timeout) {
                    clearInterval(poll); // Stop polling
                    reject(false); // Handle timeout error => Payment Declined
                }
            } catch (error) {
                clearInterval(poll); // Stop polling
                reject(false); // If error response => Payment declined
            }
        }, pollInterval);
    });
};

//API Call for challenge verification
//Called when 3DS is Challeged to obtain challenge result
export const challengeVerification = async (transactionRef, challengeRef) => {
    console.log("VERIFYING CHALLENGE")

    const url = baseURL + "/WPChallengeVerification"

    const requestBody =
    {
        "transactionReference": transactionRef,
        "challengeReference": challengeRef
    }

    try {
        const response = await makePostRequest(url, requestBody)

        //Extract Parameter from Response
        const outcome = response.data.message.outcome;
        if (outcome === "authenticated") {
            console.log("AUTHENTICATED");
            const authVer = response.data.message.authentication.version;
            const authVal = response.data.message.authentication.authenticationValue;
            const authEci = response.data.message.authentication.eci;
            const authTransactionId = response.data.message.authentication.transactionId;
            return [outcome, authVer, authVal, authEci, authTransactionId];
        } else {
            console.log(response.data);
            return null;
        }


    } catch (error) {
        return null;

    }
}

//API For getting User details: Deposit Limit, Current Balance & Saved Cards
export const userDetails = async (selectAmount) => {
    const url = baseURL + "/WPDepositLimitWithCardDetails"

    const requestBody =
    {
        "currencyCode": currencyCode,
        "amount": selectAmount
    }

    try {
        const userDetailsResponse = await makePostRequest(url, requestBody)
        const responseError = userDetailsResponse.data.errorNo;
        const userCountry = userDetailsResponse.data.message.country;
        const userCurrency = userDetailsResponse.data.message.currency;
        const userCardNumber = userDetailsResponse.data.message.cardNumber;
        if (responseError === 0) {
            return [userCountry, userCurrency, userCardNumber];
        } else {
            return null;
        }

    } catch (error) {
        return null;
    }
}

export const cardDetails = async () => {
    const url = baseURL + "/WPCardAuthToken"
    console.log("HERE");
    const requestBody =
    {
        "currencyCode": currencyCode
    }
    try {
        const cardDetailsResponse = await makePostRequest(url, requestBody)
        const responseError = cardDetailsResponse.data.errorNo;
        const cardHref = cardDetailsResponse.data.message.cardHref;
        const cardAuthHref = cardDetailsResponse.data.message.cardAuthHref;
        if (responseError === 0) {
            return [cardHref, cardAuthHref]
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

//Apple Pay Merchant Validation
export const applePayMerchantValidation = async () => {
    const url = baseURL + "/ApplePaySessionGeneration"
    console.log()
    const requestBody =
    {
        "serverURL": "https://apple-pay-gateway.apple.com/paymentservices/startSession",
        "domainName": window.location.hostname
    }
    try {
        const applePaySession = await makePostRequest(url, requestBody)
        return applePaySession.data
    } catch (error) {
        console.log(error)
        return null
    }
}

//API Call for GPAY
export const googlePayCall = async (amount, token) => {
    const url = baseURL + "/GpayAuthorization"
    const requestBody = {
        "amount": amount * 100,
        "token": token,
    }

    try {
        const response = await makePostRequest(url, requestBody)

        console.log(response.data);
        if (response.data.outcome != 'authorized') {
            return null;
        } else {
            return [response.data.outcome, response.data._links["payments:settle"].href];
        }
    } catch (error) {
        return null;
    }

}

//API Call for Apple pay payment
export const applePayCall = async (amount, token) => {
    const url = baseURL + "/ApplePayAuthorization"

    // Assuming your original JSON response is stored in a variable called 'apiResponse'

    // Extract the required properties
    const { version, data, signature, header } = token;

    // Format the object into the desired string format
    const formattedString = `
    {
        "version": "${version}",
        "data": "${data}",
        "signature": "${signature}",
        "header": {
            "transactionId": "${header.transactionId}",
            "ephemeralPublicKey": "${header.ephemeralPublicKey}",
            "publicKeyHash": "${header.publicKeyHash}"
        }
    }
`;

    // Log the formatted string or use it in your API call
    console.log(formattedString);


    const requestBody = {
        "amount": amount * 100,
        "token": formattedString,
    }


    try {
        const response = await makePostRequest(url, requestBody)

        var applepayData = JSON.parse(response.data.applepayData)

        if (applepayData.outcome != 'authorized') {
            return [applepayData.outcome, response.data.transactionReference];
        } else {
            return [applepayData.outcome, applepayData._links["payments:settle"].href, response.data.transactionReference];
        }
    } catch (error) {
        return null;
    }

}

export const addNewBank = async (accountHolderName, accountNumber, branchTransitNumber, financialInstitutionNumber, bankName, address, address1, city, province, postalCode) => {
    console.log("ADDING NEW BANK")
    const url = baseURL + "/WPAddBankDetails"

    const requestBody =
    {
        "accountHolderName": accountHolderName,
        "accountNumber": accountNumber,
        "branchTransistNumber": branchTransitNumber,
        "financialInstitutionNumber": financialInstitutionNumber,
        "bankName": bankName,
        "address": address,
        "address1": address1,
        "city": city,
        "province": province,
        "postalCode": postalCode,
        "country": "CAD"
    }

    try {
        const addBankDetailsResponse = await makePostRequest(url, requestBody)
        console.log(addBankDetailsResponse.data);
        return true;

    } catch (error) {
        return null
    }
}

//API Call to Generate Gigadat token
export const generateInteracToken = async (amount) => {
    const url = baseURL + "/GGGeneratePaymentToken"

    const requestBody = 
    {
        "amount":amount
    }

    try {
        const generateInteracTokenResponse = await makePostRequest(url, requestBody)
        console.log(generateInteracTokenResponse.data.errorNo);
        if (generateInteracTokenResponse.data.errorNo == 0){
            const interacToken = generateInteracTokenResponse.data.message.token;
            const interacTransactionId = generateInteracTokenResponse.data.message.data.transactionId
            const gigadatPage = "https://interac.express-connect.com/webflow?transaction="+interacTransactionId+"&token="+interacToken
            return gigadatPage;        
        } else {
            return false
        }
    } catch (error){
        return null;
    }
}

export const gigadatOutcome = async (transactionId, outcome) => {
    const url = baseURL + "/GGClientDeposit"

    const requestBody = 
    {
        "transactionReference" : transactionId,
        "successFlag": outcome
    }

    try {
        const gigadatOutcomeResponse = await makePostRequest(url, requestBody)
        console.log(gigadatOutcomeResponse);
        if (gigadatOutcomeResponse.data.errorNo == 0){
            return true
        } else {
            return false
        }

    } catch (error) {
        console.log(error)
        return false
    }
}


//Axios Post Request
function makePostRequest(url, body) {
    const headers = {
        'Authorization': localStorageGet('token')
    };

    return axios.post(url, body, { headers })
}